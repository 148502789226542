import React, { useState } from 'react'
import { navigate } from 'gatsby'
import Amplify, { API } from 'aws-amplify'
import { v4 as uuid } from 'uuid'
import '../utils/styles.css'
import { 
  createCharmsRoom as CreateCharmsRoom
} from '../graphql/mutations'
import config from '../aws-exports'

Amplify.configure(config)

const CharmsRoomCreator = () => {
    const [roomId] = useState(uuid)

    async function openRoom() {
        const room = {
          id: roomId
        };
        try {
          await API.graphql({
             query: CreateCharmsRoom,
             variables: { input: room } 
          })
        } catch(err) {
          console.log('error in openRoom', err);
        }
      }
       return (
          <button 
            type="button" 
            className="text-white shadow-orange bg-charmsorange relative px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold rounded-md"
            onClick={() => {
                openRoom();
                navigate(
                    `/room/${roomId}/`
                )
            }}
          >
            <span className="sm:hidden">Play!</span>
            <span className="hidden sm:block">Play Charms Online!</span>
          </button>
        );
}

export default CharmsRoomCreator