import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import CharmsRoomCreator from "../components/playCharms"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Twitter, Facebook } from "react-social-sharing"
import addToMailchimp from "gatsby-plugin-mailchimp"

const IndexPage = ({ location }) => {
  const {
    antiracism,
    gamecontents,
    gamebox,
    questcards,
    charmset,
    topimage,
  } = useStaticQuery(
    graphql`
      query {
        antiracism: file(
          relativePath: {
            eq: "Charms_AntiRacism_CoverImage_Prepublication-Cropped2.png"
          }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gamecontents: file(relativePath: { eq: "gameContents2019.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gamebox: file(relativePath: { eq: "CHARMS_BOX.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        questcards: file(relativePath: { eq: "Cards_KS.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        charmset: file(relativePath: { eq: "charmsSet.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        reference: file(relativePath: { eq: "referenceCards.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        knowledge: file(relativePath: { eq: "knowledgeCharm.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        topimage: file(relativePath: { eq: "MAIN_TopImage.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const [email] = useState("")
  const [msg, setMsg] = useState("")
  const url = "https://charmsgame.com"

  const handleSubmit = async e => {
    e.preventDefault()
    addToMailchimp(email, msg)
      .then((msg, result) => {
        {
          console.log("msg", `${result}, ${msg}`)
          setMsg("Thank you for subscribing to Charms News!")
          if (result !== "success") {
            setMsg("Please enter your email to get Charms News")
          }
          throw msg
        }
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  return (
    <Layout location={location}>
      <SEO title="Charms Game" />
      <nav className="bg-charmsbackground mt-4 md:mt-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20">
            <div className="flex">
              <div className="text-4xl md:text-5xl flex items-center flex-col self-center mt-2 sm:mt-0 sm:flex-row leading-none mb-2 sm:mb-0 md:leading-normal tracking-wide font-fidopro">
                <span className="self-start bg-clip-text text-transparent bg-gradient-to-r from-charmspurple to-charmsblue">
                  CHARMS:
                </span>
                <span className="sm:pl-1 -mt-1 sm:mt-0 bg-clip-text text-transparent bg-gradient-to-r from-charmspurple to-charmsblue">
                  A Game of Insight
                </span>
              </div>
            </div>
            <div className="flex mt-4 sm:mt-0 sm:items-center">
              <div className="flex-shrink-0">
                <span className="rounded-md shadow-sm ">
                  <CharmsRoomCreator />
                </span>
              </div>
            </div>
          </div>
          <p className="font-opensansSemiBold mt-4 sm:mt-0 text-lg">
            Welcome! CHARMS is the award-winning game of insight. Play the board
            game or online version to jumpstart dialogues, spark writing
            activities, or guide brainstorming sessions on topics of your
            choice.
          </p>
        </div>
      </nav>
      <main className="max-w-6xl mx-auto lg:relative bg-charmsbackground space-y-6">
        <section
          role="contentInfo"
          aria-label="Introducing CHARMS: A Game of Insight"
        >
          <Img
            className="w-full"
            fluid={topimage.childImageSharp.fluid}
            alt="An opened box revealing the board and floating pieces used in CHARMS: A Game of Insight."
          />
        </section>
        <section role="contentinfo" aria-label="Quests for Antiracism">
          <div className="md:grid md:grid-cols-2">
            <div className="text-center">
              <div className="mt-3 flex flex-col sm:space-y-4 lg:space-y-8">
                <p className="text-3xl tracking-normal leading-tight font-fidopro lg:text-4xl sm:leading-none md:tracking-wide">
                  <span className="text-black sm:bg-clip-text md:text-transparent md:bg-gradient-to-r md:from-gray-900 md:to-yellow-600">
                    Quests for Antiracism is here!
                  </span>
                </p>
                <p className="px-4 sm:px-8 mt-3 max-w-md mx-auto text-lg font-opensansSemiBold text-gray-900 md:mt-5 md:max-w-3xl">
                  Quests for Antiracism can expand CHARMS: A Game of Insight or
                  be used with the free Charms Online version (at button above).
                  Its questions can also be used for dialogue, reflection, or
                  writing prompts on your own or with any size group.
                </p>
                <div className="sm:flex text-center sm:justify-center">
                  <button className="rounded-md mt-3">
                    {/* https://www.kickstarter.com/projects/studio9games/charms-quests-for-antiracism?ref=user_menu */}
                    <a href="https://studio9inc.com/games/charms-antiracism">
                      <span
                        className="text-white shadow-orange bg-charmsorange
                py-2 md:text-lg rounded-md mt-3 sm:mt-0
                w-full flex items-center justify-center px-4
                border border-transparent text-base leading-6 font-opensansSemiBold"
                      >
                        Order Full Version
                      </span>
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col px-4 justify-center mt-4 md:mt-0">
              <Img
                className="w-64 mx-auto"
                fluid={antiracism.childImageSharp.fluid}
                alt="The Quests for Antiracism art shows rings of charms pieces over colors of yellow, red, orange and black."
              />
              <div className="text-center mt-4">
                <button className="rounded-md shadow">
                  <a href="https://studio9games.s3-us-west-1.amazonaws.com/Charms_AntiRacism_Sample_Prepublication.pdf">
                    <span
                      className="text-white shadow-orange bg-charmsorange
                md:text-lg rounded-md
                w-full flex items-center justify-center px-4 py-2
                border border-transparent text-base leading-6 font-opensansSemiBold"
                    >
                      Download Free Sample
                    </span>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section
          className="mx-auto max-w-7xl w-full"
          role="contentinfo"
          aria-label="Charms Introduction"
        >
          <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-charmspurple to-charmsblue leading-tight text-3xl px-4 font-fidopro">
            Have a burning question?
          </h2>
          <div className="md:grid md:grid-cols-2 px-4">
            <div className="flex-col sm:w-full order-1 sm:py-2">
              <p className="font-opensansSemiBold text-lg mt-4 sm:mt-0 text-gray-900 px-4 sm:p-6">
                Roll and interpret inspiring game pieces to see who can discover
                the most amazing insights to each player's question.
              </p>
              <div className="w-full px-4">
                <Img
                  fluid={charmset.childImageSharp.fluid}
                  alt="A row of charms from the game."
                />
              </div>
            </div>
            <div className="order-2">
              <iframe
                className="w-full px-4"
                title="Introduction to Charms"
                width="450"
                height="253"
                src="https://www.youtube.com/embed/os30LVhMv8k"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div className="px-4 font-opensansSemiBold text-lg">
                Here's a demonstration and review of CHARMS: A Game of Insight
                by ttpm.
              </div>
            </div>
          </div>
        </section>
        <section
          className="mx-auto max-w-7xl w-full mt-3"
          role="contentinfo"
          aria-label="Honors and Awards"
        >
          <div className="md:grid md:grid-cols-2">
            <div>
              <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-charmspurple to-charmsblue leading-tight text-3xl p-4 md:text-3xl font-fidopro">
                Check out these honors &amp; awards
              </h2>
              <div className="flex justify-center sm:-ml-20 -mt-4 sm:-mt-6 mb-2 text-md font-opensansSemiBold">
                (For CHARMS: A Game of Insight)
              </div>
              <div className="flex justify-center">
                <ul className="list-disc text-gray-900 text-lg space-y-2 font-opensansSemiBold px-10 ml-4">
                  <li>
                    <span>A Parent's Choice 2018 Recommended Award Winner</span>
                  </li>
                  <li>
                    <a
                      href="https://www.gameosity.com/2018/04/30/14-board-games-win-in-the-serious-games-competition/"
                      className="underline"
                    >
                      A Serious Play 2018 Silver Medal Award Winner
                    </a>
                  </li>
                  <li>
                    <p>
                      Selected to be a 2018 Mensa Mind Games&reg; Competitor
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <h2 className="leading-tight bg-clip-text text-transparent bg-gradient-to-r from-charmspurple to-charmsblue text-3xl p-4 md:text-3xl font-fidopro">
                Get CHARMS news in your inbox
              </h2>
              <div className="flex justify-center">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  className="form-input w-1/2 pl-2
            font-opensansSemiBold sm:text-lg sm:leading-5 border-2 border-gray-900"
                  placeholder="you@example.com"
                />
                <div className="self-center">
                  <button
                    onClick={handleSubmit}
                    className="text-white shadow-orange bg-charmsorange relative px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold "
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="flex justify-center">{msg}</div>
              <div className="flex justify-center pt-10">
                <button className="text-white shadow-orange bg-charmsorange relative px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold rounded-md ">
                  <a href="https://studio9inc.com/games/charms">
                    Order Charms Here!
                  </a>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section
          className="mx-auto max-w-7xl w-full"
          role="contentinfo"
          aria-label="How to play Charms"
        >
          <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-charmspurple to-charmsblue leading-tight text-3xl p-4 md:text-3xl font-fidopro">
            How do you play CHARMS?
          </h2>
          <div className="sm:px-4 sm:mt-3">
            <h3 className="text-gray-900 text-xl font-opensansExtraBold px-4 mt-3">
              Understand the game in 4 basic steps
            </h3>
            <ul className="list-decimal text-gray-900 font-opensansSemiBold px-10">
              <li>
                Draw a card, read or say your own question to the group, and
                roll 9 random charms onto the board. Remove “out of bounds”
                charms—pieces that landed off the board or on a dividing line
                between any sections of the board.
              </li>
              <li>
                Use the KEY CARD to review charm meanings. Other players quickly
                write an answer to your question based on the charms, where they
                remain on the board, and what this could mean for you.
              </li>
              <li>
                The answers are read aloud. You select the one that best answers
                your question as the winner and its author is revealed.
              </li>
              <li>
                Pass the charms to the left and play again until everyone has
                asked a question. See who can discover the most winning answers.
              </li>
            </ul>
            <h3 className="text-gray-900 text-xl px-4 mt-3 font-opensansExtraBold text-left">
              Overview
            </h3>
            <p className="font-opensansSemiBold text-gray-900 px-4">
              (3-8 players, ages 12 and up, 30-90 minutes): Charms is an
              enjoyable and engaging, easy to learn game where each player takes
              a turn as the Quester by asking a question. Everyone else finds
              their inner sage and tries to write the wisest answer based on the
              Quester's roll of the charms, where they landed, and what this
              could mean for the Quester. The Quester then picks the winning
              answer. The player with the most winning answers wins the game.
            </p>
          </div>
        </section>
        <section
          className="mx-auto max-w-7xl w-full"
          role="contentinfo"
          aria-label="Contents of the Box"
        >
          <div className="md:grid md:grid-cols-2">
            <Img
              className="mt-3 w-full ml-0 sm:ml-4"
              fluid={gamecontents.childImageSharp.fluid}
              alt="Showing the contents of Charms game box."
            />
            <div className="ml-4">
              <h3 className="text-gray-900 my-6 ml-4 font-opensansExtraBold text-xl text-left">
                Each game box has:
              </h3>
              <ul className="list-disc text-gray-900 font-opensansSemiBold px-10">
                <li>50 Charms Cards</li>
                <li>25 Charms (Dice, Wooden shapes and Meeples, Gems)</li>
                <li>8 Quick Reference Key Cards</li>
                <li>8 Pencils</li>
                <li>1 Game Board</li>
                <li>1 Rules Sheet (with cooperative options)</li>
                <li>1 Pad of Paper (50 sheets)</li>
                <li>1 Charms Bag</li>
                <li>1 Sand Timer</li>
              </ul>
              <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-charmspurple to-charmsblue leading-tight text-3xl pt-4 md:p-4 font-fidopro">
                Who made CHARMS?
              </h2>
              <p className="font-opensansSemiBold text-gray-900 px-4">
                Created by:{" "}
                <a
                  className="underline"
                  href="https://www.linkedin.com/in/lisablecker/"
                >
                  Lisa Blecker
                </a>
                ,{" "}
                <a
                  className="underline"
                  href="https://www.julieburnswalker.com/"
                >
                  Julie Burns Walker
                </a>
                , and{" "}
                <a
                  className="underline"
                  href="https://www.linkedin.com/in/c-aaron-kreader/"
                >
                  C. Aaron Kreader
                </a>
              </p>
            </div>
          </div>
        </section>
        <section
          className="mx-auto max-w-7xl"
          role="contentinfo"
          aria-label="Reviews and Testimonials"
        >
          <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-charmspurple to-charmsblue leading-tight text-3xl mt-3 px-4 md:text-3xl font-fidopro">
            Reviews and Testimonials
          </h2>
          <div className="md:grid md:grid-cols-2 md:px-6 lg:px-8">
            <div className="py-6 px-4 sm:px-6 md:flex md:flex-col self-center md:pt-10 md:pl-16 md:pr-10 lg:pr-16">
              <blockquote className="md:flex-grow md:flex md:flex-col">
                <div className="relative text-lg leading-7 font-opensansSemiBold text-gray-900 md:flex-grow">
                  <p className="relative font-opensansSemiBold">
                    Review from{" "}
                    <a
                      className="underline"
                      href="http://www.motherhoodandmore.com/2016/08/charms-game-insight-inspiration.html#comment-43305"
                    >
                      Motherhood and More
                    </a>
                  </p>
                </div>
                <div className="flex mt-4 text-gray-900 font-opensansItalic">
                  By Annie Reneau
                </div>
              </blockquote>
            </div>
            <div className="px-4 py-6 sm:px-6 md:pt-10 md:pl-10">
              <blockquote className="md:flex-grow md:flex md:flex-col">
                <div className="relative text-lg leading-7 font-opensansSemiBold text-gray-900 md:flex-grow">
                  <svg
                    className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-charmspurple"
                    fill="currentColor"
                    viewBox="0 0 86 32"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative">
                    Charms is a game to build community…it’s thought provoking
                    and fun and could be kind-of life changing…I can guarantee
                    you that it will get people engaged and involved in one
                    another and make them think of strangers as neighbors. It is
                    that powerful.&#8221;
                  </p>
                </div>
                <footer className="mt-4">
                  <div className="flex text-gray-900 font-opensansItalic">
                    Susan Garcia Trieschmann, Founder of Curt’s Café
                  </div>
                </footer>
              </blockquote>
            </div>
            <div className="px-4 py-6 sm:px-6 md:py-16 md:pl-10 lg:pl-16">
              <blockquote className="md:flex-grow md:flex md:flex-col">
                <div className="relative text-lg leading-7 font-opensansSemiBold text-gray-900 md:flex-grow">
                  <svg
                    className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-charmspurple"
                    fill="currentColor"
                    viewBox="0 0 86 32"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative">
                    ...this is soooo cool fantastic great and amazing. It's like
                    Balderdash and those kinds of make-stuff-up games but
                    instead of being designed for you to show off how smart,
                    funny, clever, or sarcastic you can be, you get to use your
                    heart and mind to connect with your friends and
                    family…&#8221;
                  </p>
                </div>
                <footer className="mt-4">
                  <div className="flex text-gray-900 font-opensansItalic">
                    Melissa Sivvy
                  </div>
                </footer>
              </blockquote>
            </div>
            <div className="px-4 py-6 sm:px-6 md:flex md:flex-col md:py-16 md:pr-6 md:pl-10">
              <blockquote className="md:flex-grow md:flex md:flex-col">
                <div className="relative text-lg leading-7 font-opensansSemiBold text-gray-900 md:flex-grow">
                  <svg
                    className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-charmspurple"
                    fill="currentColor"
                    viewBox="0 0 86 32"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative font-opensansSemiBold">
                    Charms is more than a game, it is a communication tool that
                    can bring families and friends closer together through great
                    conversations! Everyone can appreciate hearing new insights
                    into their own lives - what a fun way to facilitate sharing
                    new perspectives!&#8221;
                  </p>
                </div>
                <footer className="mt-4">
                  <div className="flex flex-col sm:flex-row text-gray-900 font-opensansItalic">
                    <div>Ann Knipp,</div>
                    <div>
                      <a
                        className="sm:ml-1 ml-0 underline"
                        href="http://www.chicagokidsmedia.com/"
                      >
                        Chicago Kids Media Founder and President
                      </a>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </section>
        <section
          className="mx-auto max-w-7xl mt-3 md:px-6 lg:px-8"
          role="contentinfo"
          aria-label="Order Charms: A Game of Insight"
        >
          <Img
            fluid={questcards.childImageSharp.fluid}
            alt="An array of cards with questions for the Charms game"
          />
          <div className="flex flex-col sm:flex-row mt-6 mb-6">
            <Img
              className="w-full md:w-1/2"
              fluid={gamebox.childImageSharp.fluid}
              alt="Showing the game box of Charms"
            />
            <div className="self-center text-center mx-auto py-6 md:pl-16">
              <button className="text-white shadow-orange bg-charmsorange relative px-4 py-2 border border-transparent text-sm md:text-lg leading-5 font-opensansSemiBold rounded-md">
                <a href="https://studio9inc.com/games/charms">
                  Order CHARMS Here!
                </a>
              </button>
              <div className="flex-col sm:flex-row py-4">
                <p className="self-center text-xl font-opensansExtraBold">
                  Spread the word on
                </p>
                <Twitter solid small link={url} />
                <Facebook solid small link={url} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage
